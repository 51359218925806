.glass-background {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Trasparente per l'effetto glass */
  backdrop-filter: blur(10px); /* Applica l'effetto di sfocatura */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Ombra */
  border: 0.5px solid rgba(255, 255, 255, 0.5);
}
.glass-background-color {
  background-color: rgba(
    230,
    139,
    255,
    0.15
  ); /* Trasparente per l'effetto glass */
  backdrop-filter: blur(10px); /* Applica l'effetto di sfocatura */
  border-radius: 16px; /* Bordo stondato */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Ombra */
  border: 0.5px solid rgba(77, 210, 230, 0.5);
}
.glass-error {
  background-color: rgba(
    198,
    11,
    11,
    0.15
  ); /* Trasparente per l'effetto glass */
  backdrop-filter: blur(10px); /* Applica l'effetto di sfocatura */
  border-radius: 16px; /* Bordo stondato */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Ombra */
  border: 0.5px solid rgba(216, 12, 12, 0.5);
}
.glass-background-color-btn {
  background-color: rgba(255, 147, 242, 0.15);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgba(207, 77, 230, 0.5);
}
body,
html {
  overflow-x: hidden;
  background: radial-gradient(circle, #0a1646 20%, #131313 70%);
  background-color: #131313;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.rotating-light {
  position: relative;

  overflow: hidden;
}

.rotating-light::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; /* Deve corrispondere al container */
  padding: 4px; /* Spessore della luce */
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    radial-gradient(circle, #ff0000, #00ff00, #0000ff, #ff0000);
  background-size: 300% 300%;
  animation: light-spin 3s linear infinite;
}

@keyframes light-spin {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
